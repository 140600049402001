/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    del: "del",
    ul: "ul",
    li: "li",
    strong: "strong",
    pre: "pre",
    code: "code",
    ol: "ol",
    br: "br",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-9-rope-bridge"
  }, "Day 9: Rope Bridge"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/9"
  }, "https://adventofcode.com/2022/day/9")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_09.rs"
  }, "my solution in\nRust"))), "\n", React.createElement(_components.p, null, "The expedition has to cross a rickety rope bridge."), "\n", React.createElement(_components.p, null, "You decide to distract yourself by thinking about ", React.createElement(_components.del, null, "food"), " rope physics."), "\n", React.createElement(_components.p, null, "Consider a rope with knots at each end."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A knot at the start, the ", React.createElement(_components.strong, null, "head"), "."), "\n", React.createElement(_components.li, null, "A knot at the end, the ", React.createElement(_components.strong, null, "tail"), "."), "\n"), "\n", React.createElement(_components.p, null, "When the head moves far enough away, the tail has to follow."), "\n", React.createElement(_components.p, null, "The question does some magical handwaving, and now knots can be represented on a 2D grid of integers."), "\n", React.createElement(_components.p, null, "Today’s input is a list of motions the head takes."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "R 4\nU 4\nL 3\nD 1\nR 4\nD 1\nL 5\nR 2\n")), "\n", React.createElement(_components.p, null, "This series of motions moves the head right four steps, then up four steps, then left three steps, then down one step, and so on."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "It’s a short rope, consisting only of the head and the tail, with no space in between."), "\n", React.createElement(_components.p, null, "The head and the tail must always be touching."), "\n", React.createElement(_components.p, null, "Touching means one of 2 thing is true:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Adjacent in one of the 8 directions (horizontal, vertical, or diagonal)"), "\n", React.createElement(_components.li, null, "Completely overlapping."), "\n"), "\n", React.createElement(_components.p, null, "If the head is ever 2 steps away from the tail (meaning: they are no longer touching), the tail must catch up."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If the head is ever two steps directly up, down, left, or right from the tail, the tail must also move one step in that direction so it remains close enough."), "\n", React.createElement(_components.li, null, "If the head and tail aren’t touching and aren’t in the same row or column, the tail always moves one step diagonally to keep up"), "\n"), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/9"
  }, "The question"), " includes a number of visual examples of these rules.\nI highly recommend looking at them!")), "\n", React.createElement(_components.p, null, "The head and the tail both start at the same position, at the coordinates (0,0)."), "\n", React.createElement(_components.p, null, "The question asks how many positions the ", React.createElement(_components.strong, null, "tail of the rope visited at least once"), "?"), "\n", React.createElement(_components.p, null, "The positions the tail visited can be tracked in a set.\nFor every line in the input, we simulate the rope, and insert the new position of the tail into that set."), "\n", React.createElement(_components.p, null, "In pseudocode, that would be:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "let mut seen = HashSet::new();\nlet starting_position = (0, 0);\nlet mut head = starting_position;\nlet mut tail = starting_position;\nseen.insert(tail);\n\nfor step in steps {\n    simulate_rope(step);\n    seen.insert(tail);\n}\n")), "\n", React.createElement(_components.p, null, "I decided to make a ", React.createElement(_components.code, null, "Coord"), " struct to represent a coordinate."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "struct Coord {\n    x: isize,\n    y: isize,\n}\n")), "\n", React.createElement(_components.p, null, "To start writing out the solution:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "use std::collections::HashSet;\n\n#[derive(Eq, Hash, PartialEq, Clone, Copy)]\nstruct Coord {\n    x: isize,\n    y: isize,\n}\n\npub fn part_1() -> usize {\n    let input = std::fs::read_to_string(\"src/day09.txt\").unwrap();\n    let start = Coord { x: 0, y: 0 };\n    let mut head = start;\n    let mut tail = start;\n    let mut seen = HashSet::new();\n    seen.insert(tail);\n    \n    for line in input.lines() {\n        let (dir, amount) = line.split_once(' ').unwrap();\n        let amount = amount.parse().unwrap();\n        \n        for _ in 0..amount {\n            // move head\n            // catch up tail if needed\n            // insert the tail into the seen set if it moved\n        }\n    }\n    seen.len()\n}\n")), "\n", React.createElement(_components.p, null, "The part where we move the head is straightforward, it’s the moving of the tail that’s the tricky part."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "match dir {\n    \"U\" => head.y -= 1,\n    \"D\" => head.y += 1,\n    \"L\" => head.x -= 1,\n    \"R\" => head.x += 1,\n    _ => panic!(\"tried to move in an invalid direction\"),\n};\n")), "\n", React.createElement(_components.p, null, "To do that, we first determine if the tail is touching the head.\nWe do that by calculating the difference between the head and the tail first."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let diff = Coord {\n    x: head.x - tail.x,\n    y: head.y - tail.y,\n};\n// if not touching, move tail and insert it into seen\n")), "\n", React.createElement(_components.p, null, "The knots aren’t touching if the distance in any direction is larger than 1."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    hl: "5"
  }, "let diff = Coord {\n    x: head.x - tail.x,\n    y: head.y - tail.y,\n};\nlet not_touching = diff.x.abs() > 1 || diff.y.abs() > 1;\n")), "\n", React.createElement(_components.p, null, "The way the tail catches up is described by the rules above.", React.createElement(_components.br), "\n", "It turns out that catching up is equal to adding the sign of the difference in each direction!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "tail.x += diff.x.signum();\ntail.y += diff.y.signum();\n")), "\n", React.createElement(_components.p, null, "The updated code is the answer to part1!"), "\n", React.createElement(_components.h3, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "use std::collections::HashSet;\n\n#[derive(Eq, Hash, PartialEq, Clone, Copy)]\nstruct Coord {\n    x: isize,\n    y: isize,\n}\n\npub fn part_1() -> usize {\n    let input = std::fs::read_to_string(\"src/day09.txt\").unwrap();\n    let start = Coord { x: 0, y: 0 };\n    let mut head = start;\n    let mut tail = start;\n    let mut seen = HashSet::new();\n    seen.insert(tail);\n    \n    for line in input.lines() {\n        let (dir, amount) = line.split_once(' ').unwrap();\n        let amount = amount.parse().unwrap();\n        \n        for _ in 0..amount {\n            // move head\n            match dir {\n                \"U\" => head.y -= 1,\n                \"D\" => head.y += 1,\n                \"L\" => head.x -= 1,\n                \"R\" => head.x += 1,\n                _ => panic!(\"tried to move in invalid direction\"),\n            };\n\n            // determine if head and tail are touching\n            let diff = Coord {\n                x: head.x - tail.x,\n                y: head.y - tail.y,\n            };\n            let not_touching = diff.x.abs() > 1 || diff.y.abs() > 1;\n\n            // update tail and insert it into the seen set if needed\n            if not_touching {\n                tail.x += diff.x.signum();\n                tail.y += diff.y.signum();\n                seen.insert(tail);\n            }\n        }\n    }\n    \n    seen.len()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "You now have to simulate a rope that has 10 knots instead of 2."), "\n", React.createElement(_components.p, null, "The question asks how many positions the ", React.createElement(_components.strong, null, "tail of the rope visited at least once"), "?"), "\n", React.createElement(_components.p, null, "As in previous days, a lot of the part1 code is reused."), "\n", React.createElement(_components.p, null, "Instead of 2 variables for ", React.createElement(_components.code, null, "head"), " and ", React.createElement(_components.code, null, "tail"), ", we now track 10 variables.\nOne for each knot in the rope."), "\n", React.createElement(_components.p, null, "In the implementation, each knot is stored as a coordinate in a list called ", React.createElement(_components.code, null, "rope"), "."), "\n", React.createElement(_components.p, null, "The rope follows the same rules as in part1, so that logic remains unchanged."), "\n", React.createElement(_components.p, null, "For each step:"), "\n", React.createElement(_components.p, null, "We first move the head of the entire rope."), "\n", React.createElement(_components.p, null, "Then, we iterate over 2 sections of the rope at a time."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The first is the ", React.createElement(_components.strong, null, "head"), " of that section and never moves."), "\n", React.createElement(_components.li, null, "The second is the ", React.createElement(_components.strong, null, "tail"), " of that section and catches up if necessary."), "\n"), "\n", React.createElement(_components.p, null, "If the tail of a section is also the tail of the entire rope, we insert it into ", React.createElement(_components.code, null, "seen"), " if it moved."), "\n", React.createElement(_components.h3, {
    id: "final-code-1"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "use std::collections::HashSet;\nuse itertools::Itertools;\n\n#[derive(Eq, Hash, PartialEq, Clone, Copy)]\nstruct Coord {\n    x: isize,\n    y: isize,\n}\n\npub fn part_2() -> usize {\n    let input = std::fs::read_to_string(\"src/day09.txt\").unwrap();\n    let start = Coord { x: 0, y: 0 };\n    let mut rope = vec![start; 10];\n    let mut seen = HashSet::new();\n    seen.insert(start);\n\n    for line in input.lines() {\n        let (dir, amount) = line.split_once(' ').unwrap();\n        let amount: u8 = amount.parse().unwrap();\n\n        for _ in 0..amount {\n            // move head of the whole rope\n            match dir {\n                \"U\" => rope[0].y -= 1,\n                \"D\" => rope[0].y += 1,\n                \"L\" => rope[0].x -= 1,\n                \"R\" => rope[0].x += 1,\n                _ => panic!(\"tried to move in an invalid direction\"),\n            };\n\n            // move the rest of the rope\n            for (head_idx, tail_idx) in (0..rope.len()).tuple_windows() {\n                // determine if head and tail are touching\n                let diff = Coord {\n                    x: rope[head_idx].x - rope[tail_idx].x,\n                    y: rope[head_idx].y - rope[tail_idx].y,\n                };\n                let not_touching = diff.x.abs() > 1 || diff.y.abs() > 1;\n\n                // update tail and insert it into the seen set if needed\n                if not_touching {\n                    rope[tail_idx].x += diff.x.signum();\n                    rope[tail_idx].y += diff.y.signum();\n                    if tail_idx == rope.len() - 1 {\n                        seen.insert(rope[rope.len() - 1]);\n                    }\n                }\n            }\n        }\n    }\n\n    seen.len()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
